.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #333;
  color: white;
}

.app-header .MuiIconButton-root {
  color: white;
}

.main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.chat-component {
  flex: 1;
  background-color: #121212;
  padding: 1rem;
  overflow-y: auto;
}

.settings-panel {
  width: 300px;
  background-color: #222;
  color: white;
  padding: 1rem;
}

.sidebar {
  width: 250px;
  background-color: #222;
  color: white;
  padding: 1rem;
}
