/* @import "@radix-ui/colors/black-alpha.css";
@import "@radix-ui/colors/green.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/violet.css"; */

/* reset */
/* button,
fieldset,
input {
  all: unset;
} */

.DialogOverlay {
  background-color: var(--black-a9);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.text-black {
  color: #1a1a1a;
}

.DialogContent {
  background-color: #1a1a1a;
  border: solid 1px #828282;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 550px;
  max-height: 85vh;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  overflow-y: auto;
  overflow-y: auto;
  color: #e5e5e5;
}
.DialogContent:focus {
  outline: none;
}

.DialogTitle {
  margin: 0;
  font-weight: 500;
  color: var(--mauve-12);
  font-size: 17px;
}

/* .DialogDescription {
  margin: 10px 0 20px;
  color: var(--mauve-11);
  font-size: 15px;
  line-height: 1.5;
} */

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
}
.Button.violet {
  background-color: white;
  color: var(--violet-11);
  box-shadow: 0 2px 10px var(--black-a7);
}
.Button.violet:hover {
  background-color: var(--mauve-3);
}
.Button.violet:focus {
  box-shadow: 0 0 0 2px black;
}
.Button.green {
  background-color: var(--green-4);
  color: var(--green-11);
}
.Button.green:hover {
  background-color: var(--green-5);
}
.Button.green:focus {
  box-shadow: 0 0 0 2px var(--green-7);
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet-11);
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
}
.IconButton:hover {
  background-color: var(--violet-4);
}
.IconButton:focus {
  box-shadow: 0 0 0 2px var(--violet-7);
}

.Fieldset {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}

.Label {
  font-size: 15px;
  color: var(--violet-11);
  width: 90px;
  text-align: right;
}

.Input {
  width: 100%;
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 15px;
  line-height: 1;
  color: var(--violet-11);
  box-shadow: 0 0 0 1px var(--violet-7);
  height: 35px;
}
.Input:focus {
  box-shadow: 0 0 0 2px var(--violet-8);
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.DialogContent .settings-panel {
  all: unset;
}
.DialogContent .setting-col {
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0;
}
.DialogContent .margin-t {
  margin-top: 15px;
}
.DialogContent.label-pad {
  padding: 0.5rem 0;
}
.DialogContent .setting-textarea {
  height: 4rem;
  border-radius: 10px;
  border: 1px solid #828282;
  background-color: #212121;
  resize: none;
  padding: 6px;
  color: #f6f7f9;
  margin: 0.25rem 0;
}
.DialogContent .color-accent {
  accent-color: #50c878;
}
.DialogContent .diff-pad {
  padding-top: 1rem;
}
.DialogContent .sequence-text-size {
  font-size: 12px;
  color: #828282;
}
.DialogContent .text-right {
  float: right;
}
.DialogContent .setting-footer {
  display: none;
}

@media (max-width: 575px) and (min-width: 279px) {
  .DialogContent {
    padding: 10px;
  }
}

/* subscription Modal */
.DialogCustomWidth {
  max-width: 900px;
  color: #e5e5e5;
}

.DialogCustomWidth-signup {
  max-width: 547px;
  color: #e5e5e5;
}

/* .subscription-panel {
  background-color: #1f2937;
  color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
} */

.subscription-panel h2 {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.h2modal {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.subscription-panel .description {
  margin-bottom: 1.5rem;
}

.subscription-panel .options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

@media (min-width: 640px) {
  .subscription-panel .options {
    flex-direction: row;
  }
}

.subscription-panel .option {
  flex: 1;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subscription-panel .option.yearly {
  background-color: #50c878;
  color: #000000;
}

.subscription-panel .option.monthly {
  background-color: #000000;
  border: solid 2px #828282;
  color: #e5e5e5;
}
.ban-image {
  position: absolute;
  height: 158px;
  top: 9%;
  left: 28%;
}
.ban {
  position: relative !important;
  background: rgb(255 255 255 / 48%) !important; /* White with transparency */
  backdrop-filter: blur(10px) !important; /* Apply the blur effect */
  -webkit-backdrop-filter: blur(10px) !important; /* For Safari support */
}

.subscription-panel .option .title {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
}

.subscription-panel .option .price {
  font-size: 1.475rem;
  font-weight: 700;
}

.subscription-panel .option .old-price {
  font-size: 1.35rem;
  text-decoration: line-through;
}

.subscription-panel .option .save {
  font-size: 0.9rem;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  align-self: center;
  background-color: white;
  padding: 5px 15px;
  border-radius: 10px;
}

.subscription-panel .option .total-price {
  font-size: 1.15rem;
  font-weight: 700;
  /* margin-top: 0.5rem; */
  margin-bottom: 0;
}
.subscription-panel .option .total-price-old {
  font-size: 1.05rem;
  font-weight: 700;
  /* margin-top: 0.5rem; */
  margin-bottom: 0;
}

.subscription-panel .footer-subscription {
  font-size: 0.75rem;
  margin-bottom: 1.8rem;
  margin-top: 20.24px;
  background-color: unset;
  text-align: center;
  color: #828282;
}

.dflexbetween {
  display: flex;
  justify-content: space-between;
}

.dflex {
  display: flex;
  gap: 0 10px;
  align-items: center;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 0%);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscription-panel .option:hover {
  background-color: rgb(81 255 139);
  cursor: pointer;
}
.subscription-panel .hov:hover {
  background-color: rgb(57 57 57);
  cursor: pointer;
}

.sideBar-subs-panel {
  z-index: 1201;
  position: absolute;
}

.sideBar-subs-panel-alert {
  z-index: 1301;
  position: absolute;
}

.btnCloseModal {
  background-color: transparent;
  padding: 10px 20px;
  color: white;
  border: solid 1px #828282;
  cursor: pointer;
  border-radius: 10px;
  font-size: 16px;
  background-color: #121212;
  /* width: 298px; */
}

.btnCloseModal:hover {
  background-color: rgb(57 57 57);
  cursor: pointer;
}

.overlay-subscribed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-up-modal {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.currency-symbol {
  left: -1px;
  top: 70%;
  pointer-events: none;
  color: #333;
  border-radius: 10px 0 0 10px;
  background-color: #50c878;
  padding: 6px 15px;
}

.currency-symbol-focus {
  outline: 2px solid #50c878;
}

.top-up-modal input {
  font-size: 16px;
  height: 36px;
  width: -webkit-fill-available;
  border: none;
  border-radius: 0 10px 10px 0;
  padding: 0px 10px;
}

.topUp-input:focus-visible {
  outline: 2px solid #50c878;
}

.topup-label {
  font-size: 14px;
}

.error-top-up {
  margin-top: 5px;
  font-size: 14px;
}

.total-top-up-credits {
  margin: 5px 0 0 0;
  font-size: 14px;
  color: #828282;
}

.button-top-up {
  margin: 10px 0 5px 0;
}

.top-up-button-div {
  display: flex;
  justify-content: end;
  flex-direction: column;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.auto-recharge-settings {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.auto-recharge-settings h3 {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-button,
.cancel-button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-button {
  background-color: #50c878;
  color: rgb(0, 0, 0);
}

.cancel-button {
  background-color: #f8f9fa;
  color: #6c757d;
}

.auto-topup-error p {
  margin: 0;
}

.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #50c878 !important;
}
.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: #50c878c2 !important;
}

.card-element-auto-recharge {
  background-color: white;
  padding: 16px;
  border-radius: 10px;
}

.justify-end {
  justify-content: end;
}

.button-auto-recharge-save {
  margin: 10px 0 0 0;
}

.flex-col {
  flex-direction: column;
}

.card-set-recharge-space {
  margin-top: 16px;
  padding: 10px;
  background-color: #000000;
  border-radius: 10px;
  border: solid 1px #828282;
}

.invalid-card {
  background-color: gray;
}

.trans-back {
  background-color: transparent;
}

.existing-card {
  background: black;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid;
}
.subscription-panel .options-remove-this-tag-if-added-another-product {
  display: flex;
  flex-direction: column !important;
  gap: 1rem;
  position: relative;
  align-items: center !important;
}
