@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}
* {
  font-family: "San Francisco";
}

/*Content of Sidebar*/

.sidebar {
  width: 305px;
  background-color: #222;
  /* padding: 1rem; */
  color: #f6f7f9;
  display: flex;
  position: fixed;
  flex-direction: column;
  overflow-y: auto;
  flex-shrink: 0;
  bottom: 0;
  left: 0;
  top: 71px;
  height: calc(100% - 71px);
}

.new-chat-button {
  padding: 8px;
  top: 0;
  /* margin-bottom: 10px; */
  border: none;
  border-radius: 10px;
  background-color: transparent;
  color: black;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  width: fit-content;
  left: 227px;
  position: relative;
}

.new-chat-button:hover {
  background-color: #50c878;
}

.left-margin-t {
  margin-top: -6px;
}

.close-left {
  position: fixed;
  border: none;
  left: 20px;
  padding: 7px 14px;
  cursor: pointer;
  background-color: #212121;
  color: #ffffff;
  size: 30px;
  caret-color: transparent;
  z-index: 1101;
  top: 85px;
  font-weight: bold;
  border-radius: 10px;
}

.close-left:hover {
  background: #50c878;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f6f7f9;
  border-radius: 20px;
  height: 50%;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #3e3e3e;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #454545;
}
.menu-footer {
  /* display: flex;
  flex-direction: column;
  align-items: stretch; */

  position: absolute;
  padding: 0;
  text-align: left;
  bottom: 20px;
}
.menu-footer-content {
  font-size: 16px;
  padding: 10px 9px;
  margin: 5px 0;
  justify-content: left;
  display: flex;
  color: #f6f7f9;
  background-color: #222;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.chat-history {
  flex: 1;
  overflow-y: auto;
  margin: 10px 0;
}

.chat-history-item {
  padding: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: #f6f7f9;
  /* margin-bottom: 10px; */
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
}
.menu-footer-content:hover {
  background-color: #2c2c2c;
}

.green-color {
  color: #50c878;
}

.image-size {
  /* height: 10%; */
  width: 22px;
  filter: invert(1);
  margin: 3px 5px;
  /* margin: 0 15px; */
}

.delete-thread-button {
  width: fit-content;
  background: transparent;
  border: none;
  right: 20px;
  /* position: absolute; */
  margin: 3px;
  padding: 0;
  visibility: hidden;
}
.delete-thread-button-image {
  height: 38px;
  padding: 8px 13px;
  border-radius: 10px;
}
.delete-thread-button-image:hover {
  background-color: #222;
}

.message-svg {
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-button {
  background-color: transparent;
  border: none;
  width: 20%;
  position: sticky;
  left: 259px;
  /* padding: 10px; */
  display: flex;
  cursor: pointer;
  justify-content: center;
  border-radius: 0px 5px 5px 0px;
}

.no-button {
  background: transparent;
  padding: 8px 24px;
  font-size: 16px;
  border-radius: 10px;
  color: white;
  border-style: solid;
  border-color: gray;
  border-width: 1px;
}

.no-button:hover {
  background: #222;
}

.yes-button {
  background: #50c878;
  padding: 8px 24px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  color: #ffffff;
}

.yes-button:hover {
  background: #50c878ad;
}

.modal-confirmation {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 0 20px 0 0;
}

.image-button-size {
  width: 40%;
  filter: invert(1);
  margin: 0 15px;
}

.chat-history-item.active {
  background-color: #2c2c2c;
  color: white;
}

.sidebar-chats:hover {
  background-color: #50c878;
}
.sidebar-chats.active:hover {
  background-color: unset;
}
.sidebar-chats:hover .delete-thread-button {
  visibility: visible;
}
.sidebar-chats.active .delete-thread-button {
  visibility: visible;
}

/* Chat container styles */
.chat-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding: 0 15px;
  height: 88.5vh;
  /* margin-bottom: 10px; */
}

.input-container button:disabled {
  background-color: #484646;
  cursor: not-allowed;
}

.close-right {
  position: fixed;
  border: none;
  right: 16px;
  top: 85px;
  padding: 7px 14px;
  cursor: pointer;
  background-color: transparent;
  color: #ffffff;
  size: 30px;
  caret-color: transparent;
  z-index: 1101;
  font-weight: bold;
  border-radius: 10px;
}
.close-right:hover {
  background: #50c878;
}

.sequence-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 8px;
}

.sequence-item {
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #212121;
  border-radius: 10px;
  border: 1px solid #828282;
  font-size: 14px;
}

.delete-button {
  margin-left: 10px;
  background-color: transparent;
  color: white;
  border: none;
  padding: 5px 8px;
  cursor: pointer;
  border-radius: 50%;
  /* align-self: center; */
  /* text-align: center; */
  font-size: 11px;
  display: flex;
  align-items: center;
}

.delete-button:hover {
  background-color: #50c878;
}

.h-100vh {
  height: 100vh;
}

.setting-col {
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0;
}
.margin-t {
  margin-top: 16px;
}
.text-right {
  float: right;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.label-pad {
  padding: 0.5rem 0;
}

.diff-pad {
  padding-top: 1rem;
}

.setting-textarea {
  height: 4rem;
  border-radius: 10px;
  border: 1px solid #828282;
  background-color: #212121;
  resize: none;
  padding: 6px;
  color: #f6f7f9;
  margin: 0.25rem 0;
}
.sequence-text-size {
  font-size: 12px;
  color: #828282;
}

.color-accent {
  accent-color: #50c878;
}
.setting-footer {
  /* position: absolute; */
  padding: 0;
  text-align: left;
  bottom: 20px;
  color: #828282;
}
/* 
.setting-col-footer {
  margin-top: 70px;
} */

.s-f-paragraph {
  font-size: 13px;
  margin: 0;
  padding: 0px;
  text-decoration: none;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #333;
  color: #f6f7f9;
  height: 64px; /* Set the height of the header */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure the header is on top */
}

.app-header .MuiIconButton-root {
  color: #f6f7f9;
}

.app-body {
  display: flex;
  flex: 1;
  margin-top: 64px; /* Push content below the header */
}

.main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #121212;
  padding: 1rem;
  overflow-y: auto;
  transition: margin 0.3s;
}

.settings-panel {
  color: #f6f7f9 !important;
  /* width: 360px; */
  background-color: #222;
  position: fixed;
  top: 71px; /* Position it below the header */
  bottom: 0;
  right: 0;
  height: calc(100% - 71px);
  z-index: 999; /* Ensure the settings panel is on top of the main content */

  display: flex;
  flex-direction: column;
}

.settings-panel-scroll {
  overflow-y: auto;
}

.settings-panel-scroll::-webkit-scrollbar {
  display: none;
}
.settings-panel .dropbtn {
  width: 243px;
}

.settings-panel .dropdown-content-CC {
  width: 243px;
}

.settings-panel .dropdown-content-CC .llm-btn {
  padding: 0px;
}

.settings-panel .dropdown-content {
  padding: 0px;
  width: 243px;
}
.settings-panel .margin-t .flex-div {
  height: 59px;
  margin-top: 20px;
}

.messages {
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
}

.input-container {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.3rem;
  background-color: #333;
  width: 60vh;
  background: #f6f7f9;
  border: solid 1px #828282;
  border-radius: 50px;
}

.input-container textarea {
  flex: 1;
  padding: 0.5rem;
  margin-right: 0.5rem;
  border: none;
  border-radius: 4px;
  background: transparent;
  font-size: 16px;
  outline: none;
  resize: none;
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;

  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.input-container button {
  border-radius: 100px;
  padding: 0.5rem 1rem;
  border: none;
  background-color: #cecece;
  color: #f6f7f9;
  cursor: pointer;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
}
.input-container button:hover {
  background: #ababab;
}

.send-svg {
  width: 16px;
  padding: unset;
}

.sidebar-chats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;

  color: white;
  margin: 10px 0;
}

.chat-tabLoader {
  height: 47px;
}
