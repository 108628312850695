.billing-container {
  max-width: 840px;
  margin: 0 auto;
}

.tabs-list {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.tabs-list button {
  background: none;
  border: none;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
  color: #fff;
  transition: background-color 0.3s ease;
}

.tabs-list button:hover {
  background-color: rgb(57 57 57);
}
.tabs-list1 button:hover {
  background-color: rgb(57 57 57);
}

.tab-active {
  border-bottom: 2px solid #50c878 !important;
  /* color: #2c5282 !important; */
}

.tab-top-up-active {
  background-color: black !important;
  border-radius: 10px;
}

.tab-content {
  /* padding: 16px 0; */
}

.credit-balance {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 12px;
}

.credit-balance2 {
  color: #f2f2f2f2;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 12px;
}

.auto-recharge-section {
  background-color: black;
  padding: 16px;
  border: solid 1px #828282;
  border-radius: 8px;
  margin-bottom: 16px;
}

.auto-recharge-section .p {
}

.modify-btn {
  display: block;
  margin-top: 8px;
  font-size: 14px;
  padding: 8px 12px;
  background-color: #50c878;
  color: #000000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.modify-btn:hover {
  background-color: rgb(78 215 124);
}

.action-buttons {
  display: flex;
  gap: 16px;
}

.add-credit-btn,
.cancel-plan-btn {
  font-size: 14px;
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-credit-btn {
  background-color: #50c878;
  color: #000000;
}

.add-credit-btn:hover {
  background-color: rgb(78 215 124);
}
.cancel-plan-btn {
  background-color: #e53e3e;

  color: #fff;
}

.cancel-plan-btn:hover {
  background-color: #e24848;
}

.subscription-options {
  display: flex;
  justify-content: space-around;
}

.option {
  width: 45%;
  padding: 5px;
  color: #ffffff;
}

.option h2 {
  font-size: 1.5em;
  margin: 0;
}

.option ul {
  list-style: none;
  padding: 0;
}
.option ul li::before {
  content: "• "; /* Bullet point symbol */
  left: 0;
  color: #ffffff; /* Set bullet color to white */
  font-size: 1em;
}

.option ul li {
  margin-bottom: 0;
}

.option a {
  color: #0066cc;
  text-decoration: none;
}

.option a:hover {
  text-decoration: underline;
}

.custom-option {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hidden {
  display: none;
}
