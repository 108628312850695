.svg-cursor {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 4px;
  animation: spin 1s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.svg-rotate {
  animation: spin 0.8s linear infinite;
  width: 16px;
  height: 16px;

  vertical-align: middle;

  border-radius: 50%;
  background-color: white;
}

.svg-image {
  height: auto;
  width: 16px;
}

/* .typing-container {
  position: relative;
  padding: 1rem;
} */