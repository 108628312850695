/* styles.css */

@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}
* {
  font-family: "San Francisco";
}

/* General Styles */
body {
  background-color: #1a1a1a; /* Dark background */
  color: #ffffff; /* White text */
  font-family: "San Francisco";
  margin: 0;
  padding: 0;
}

/* Navbar Styles */
nav {
  background-color: #222;
  padding: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav img {
  height: 32px;
  width: 32px;
}

nav a {
  color: #ffffff;
  /* margin-left: 16px; */
  text-decoration: none;
  padding: 3px;
}

nav a:hover {
  text-decoration: underline;
}

/* Welcome Section Styles */
.welcome-section {
  text-align: center;
  padding: 64px 10px;
  height: 81.5vh;
  align-content: center;
  color: whitesmoke;
}

.welcome-section h1 {
  font-size: 36px;
  font-weight: bold;
}

.welcome-section .search-bar {
  /* margin-top: 32px; */
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
}

/* .welcome-section .search-bar input {
 width: 60%;
  width: 600px;
  padding: 16px;
  border-radius: 9999px;
  border: none;
  margin-right: 16px;
} */

.welcome-section .flex-div .dropbtn {
  width: 100%; /* Fallback for older browsers */
  width: -webkit-fill-available; /* WebKit-based browsers */
  width: -moz-available; /* Firefox */
  width: fill-available; /* Future standard */
}
.welcome-section .llm-btn {
  width: 100%; /* Fallback for older browsers */
  width: -webkit-fill-available; /* WebKit-based browsers */
  width: -moz-available; /* Firefox */
  width: fill-available; /* Future standard */
}
.welcome-section .flex-div .dropdown-container {
  display: flex;
  align-items: stretch;
  width: 67vh;
  flex-direction: column;
}
.welcome-section .flex-div .dropdown-container .dropdown-content-CC {
  /* position: absolute; */
  top: 273px;
  width: 100%; /* Fallback for older browsers */
  width: -webkit-fill-available; /* WebKit-based browsers */
  width: -moz-available; /* Firefox */
  width: fill-available; /* Future standard */
}

.welcome-section .flex-div .dropdown-content {
  /* position: absolute;  */
  top: 345px;
  width: 100%; /* Fallback for older browsers */
  width: -webkit-fill-available; /* WebKit-based browsers */
  width: -moz-available; /* Firefox */
  width: fill-available; /* Future standard */
}
.welcome-section .flex-div .input-container button {
  display: flex;
  padding: 9px 20px;
  border-radius: 9999px;
  /* background-color: #ffffff; */
  color: #1a1a1a;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  align-items: center;
}
/* .welcome-section .flex-div .input-container .chat-cmponent {
  display: none;
} */

hr {
  width: 242px;
  margin: 0 5px;
  border-top: 0px solid #b0b0b0;
}

.welcome-section .advanced-settings .welcome-advance-settings {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}

.welcome-section .advanced-settings .welcome-advance-settings .image-size {
  width: 16px;
  margin: 0 3px 0 0;
}

.welcome-section .send-svg {
  margin: 0 5px;
}
.welcome-section button::before {
  content: "New chat";
}

.welcome-section button:hover {
  background-color: #e0e0e0;
}

.welcome-section .input-container {
  margin: 5px;
  /* height: 36px; */
  padding: 0.7rem 1.3rem;
  width: 72vh;
  display: flex;
  /* align-items: normal; */
  /* flex-direction: row; */
}
.welcome-section .input-container textarea {
  /* height: auto*/
  max-height: 100px;
  overflow-y: auto;
  /* padding: 0.9rem 0.5rem 0; */
}

.welcome-section .input-container button {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
  /* Bottom-right shadow */
}

.welcome-section .flex-div {
  display: unset;
  /* height: 60px; */
  display: flex;
  justify-content: center;
}

.welcome-section .advanced-settings {
  margin-top: 16px;
  color: #b0b0b0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Suggested Topics Styles */
.topic-label {
  padding: 5px 0;
  justify-content: center;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}
.topic-label span {
  display: flex;
  justify-content: flex-start;
  width: 67vh;
  font-size: 16px;
  font-size: 20px;
  font-weight: lighter;
}

.suggested-topics {
  padding: 15px 0;
  display: flex;
  /* grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); */
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  width: 67vh;
}
.suggest-topic {
  display: flex;
  justify-content: center;
  box-sizing: content-box;
}

.suggest-topic span {
  align-self: flex-start;
}

.suggested-topics .card {
  align-items: flex-start;
  background: #0000;
  border: 1px solid #828282;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 14px;
  text-align: center;
  width: 114px;
}

.suggested-topics .card:hover {
  background-color: #828282;
}

.suggested-topics .card .icon {
  font-size: 32px;
}

.suggested-topics .card .text {
  margin-top: 9px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  text-align: start;
}

/* Footer Styles */
.footer {
  padding: 16px;
  background-color: #222;
  color: #b0b0b0;
  text-align: center;
  /* position: relative; */
  /* margin-top: 15vh;
   */
  display: flex;
  bottom: 0;
  left: 0;
  justify-content: center;
  right: 0;
  align-items: center;
  height: 88px;
  gap: 50px;
}

.footer .green-color .image-size {
  /* height: 10%; */
  width: 20px;
  filter: invert(1);
  margin: 0px 5px;
  vertical-align: middle;
}

.green-color {
  color: #50c878 !important;
  text-decoration: none;
}

.footer a {
  color: white;
  margin: 0 8px;
  text-decoration: none !important;
}

.footer a:hover {
  cursor: pointer;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.avatar {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.welcome-logo {
  height: 40px;
  width: 40px;
  margin-left: 32px; /* Increased padding between the logo and the text */
}

.welcome-section2 {
  display: flex;
  align-items: center;
  /* margin-bottom: 16px; */
  justify-content: center;
}

/* Signup login */
.content-signup-login {
  height: 645px;
  padding: 64px 10px; /* display: flex; */ /*
  flex-direction: column; */
  align-content: center;
}

.signup-login {
  text-align: center;
  /* padding: 64px 0 0px; */
}

.signup-login .welcome-section2 {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: center;
}

.signup-login .welcome-section2 h1 {
  font-size: 36px;
  font-weight: bold;
}

.signup-login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.main-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-content {
  display: flex;
  flex: 1;
}

.app {
  display: flex;
  width: 100%;
  height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  background-color: #1a1a1a;
  padding: 20px;
}

.sidebar-item {
  background-color: #333;
  color: #fff;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sidebar-item.upgrade {
  color: #00ff00;
}

.sidebar-item:hover {
  background-color: #444;
}

/* Chat window styles */
.chat-window {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #333;
}

.chat-window .messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

/* Chat input styles */
.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #444;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px 0 0 5px;
  outline: none;
}

.chat-input button {
  padding: 10px 20px; /* Add more padding for better click area */
  background-color: #007bff; /* Blue background */
  border: none; /* No border */
  border-radius: 0 5px 5px 0; /* Rounded right corners */
  color: white; /* White text color */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover effects */
}

.chat-input button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.05); /* Slight scaling effect on hover */
}

.chat-input button:active {
  background-color: #003d80; /* Even darker blue when pressed */
  transform: scale(0.95); /* Slight reduction in scale when pressed */
}

.input-area {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #444;
  background-color: #2c2c2c;
}

.input-area input {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 20px;
  outline: none;
  font-size: 16px;
  background-color: #fff;
  color: black;
  margin-right: 10px;
}

.send-button {
  background-color: #cccccc;
  border: none;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.send-button:hover {
  background-color: #aaaaaa;
}

.chat-area {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #333;
}

.sidebar .advanced-settings {
  margin-top: auto; /* Push to bottom */
  color: #bbb; /* Light gray color */
  cursor: pointer; /* Pointer cursor */
  padding: 10px; /* Padding */
  border: none; /* No border */
  background-color: transparent; /* Transparent background */
  text-align: left; /* Align text to left */
  transition: color 0.3s; /* Smooth transition for color */
}

.sidebar .advanced-settings:hover {
  color: white; /* White text on hover */
}

.message-text {
  height: auto;
  display: inline-block;
  /* text-align: justify; */
  /* white-space: pre-line; */
  padding: 3px 5px;
  background: #f6f7f9;
  color: rgb(0, 0, 0);
  margin: 0;
  border-radius: 1px 10px 10px 10px;
  max-width: 600px;
  width: auto;
}

.message-text p {
  margin: 0px;
}
.message-text ol {
  margin: 0px;
}

.chat-container {
  height: 100vh;
}

.chat-container {
  padding: 20px;
  background-color: #121212;
  border-radius: 10px;
  position: relative;
  height: 100vh;
}

.chat-div {
  display: flex;
  align-items: flex-start;
  margin: 10px 0;
}

.chat-div-user {
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
  margin: 20px 0;
}

.top-margin {
  margin-top: 10px;
}

.dropdown-container {
  margin: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dropdown-container label {
  color: white;
  margin-right: 10px;
}

.dropdown-container select {
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  background-color: #1f1f1f;
  color: white;
}

.messages-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  overflow-x: auto;
  margin-bottom: 1rem;
}

.messages-container::-webkit-scrollbar {
  display: none;
}

.message-button {
  margin: 4px 2px;
  padding: 10px;
  border-radius: 50%;
  border: none;
  background-color: transparent;
}

.message-button:hover {
  background: #454545;
}

.share-button:hover {
  background: #454545;
}

.content-2 {
  display: inline-flex;
  align-items: center;
  margin-right: 100px;
}

.content-2 .css-1b5qve2 {
  all: unset;
}

.message-text-user {
  height: auto;
  display: inline-block;
  /* text-align: justify; */
  padding: 5px;
  background: #50c878;
  color: rgb(0, 0, 0);
  margin: 0;
  border-radius: 10px 1px 10px 10px;
  width: auto;
  max-width: 700px;
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.content-2-user {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 100px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content-CC {
  top: 156px;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  z-index: 1;
  border-radius: 10px;
  border: solid 1px #828282;
  background-color: #212121;
}
.dropdown-content-CC input {
  min-width: 150px;
  font-size: 16px;
  padding: 10px;
  color: #f6f7f9;
  border: none;
  background-color: transparent;
  border-radius: 10px;
  justify-content: flex-start;
  min-width: auto;
  display: flex;
  cursor: pointer;
}

.dropdown-content {
  /* position: fixed; */
  top: 223px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 297px;
  z-index: 1;
  border-radius: 10px;
  border: solid 1px #828282;
  background-color: #212121;
}

.dropdown-content input {
  min-width: 150px;
  font-size: 16px;
  padding: 10px;
  color: #f6f7f9;
  border: none;
  background-color: transparent;
  border-radius: 8px;
  justify-content: flex-start;
  min-width: auto;
  display: flex;
  cursor: pointer;
}

.hidden-show {
  display: none;
}

.dropdown-content input:hover {
  background-color: #828282;
}
.dropdown-content-CC .llm-btn:hover {
  background-color: #828282;
}

.llm-btn {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  padding: 15px;
  width: 148px;
}

.dropdown-svg {
  margin: 10px;
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropbtn {
  background-color: transparent;
  padding: 10px;
  width: 195px;
  color: white;
  font-size: 16px;
  border: solid 1px #828282;
  cursor: pointer;
  border-radius: 10px;
  font-size: 16px;
  background-color: #212121;
  width: 298px;
  /* text-align: justify; */
}

.dropbtn:hover {
  background-color: #828282;
}

.invert {
  filter: invert(1);
  width: 12px;
  height: 12px;
}

.mb-10 {
  margin-bottom: 10px;
}

.flex-div {
  display: flex;
  justify-content: center;
}
.flex-div-between {
  justify-content: space-between;
  /* height: 70px; */
}
.flex-div-column {
  display: flex;
  flex-direction: column;
}

.flex-div-row {
  /* display: flex; */
  /* align-items: flex-start; */
  position: fixed;
  top: 156px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 298px;
  z-index: 1;
  border-radius: 10px;
  border: solid 1px #828282;
  background-color: #121212;
}

.profpic-chatwindow {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
button {
  cursor: pointer;
}

.space-x-4 {
  margin-right: 10vh;
  gap: 16px;
}
.items-center img {
  margin-left: 5vh;
  cursor: pointer;
}

.credit-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.font-12px {
  font-size: 14px;
}

.nav-height {
  height: 82px;
}

.sign-in-up .nav-height {
  height: 43px;
}
.gap-10 {
  gap: 10px;
}

.gap-5 {
  gap: 3px;
}

.credit-button {
  border: solid 1px #828282;
  background: #222;
  color: #f2f2f2f2;
  border-radius: 5px;
  width: 68px;
}

.credit-button:hover {
  background: #2c2c2c;
}

.credit-box {
  border-radius: 5px;
  border: none;
  background: #50c878;
  color: black;
  padding: 0 10px;
  font-weight: 550;
}

.password-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #3a3a3a;
  color: white;
}

.error {
  color: rgb(218, 44, 44);
  font-size: 13px;
  margin-top: 5px;
  align-items: center;
  display: flex;
}

.error-image {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.input-error {
  border: 2px solid #ff1111 !important;
}

.focus:focus.email {
  outline: 1px solid white !important;
}

.subscription-panel {
  margin: 1rem;
  /* background-color: #222; */
  color: #fff;
  border-radius: 10px;
}

.gridMysubs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 5px;
}

.gridMysubsTotal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 5px;
}

.subscription-item {
  background-color: #333;
  padding: 15px;
  border-radius: 5px;
  height: 145px;
  background-color: #000;
  /* border: 2px solid #828282; */
  color: #e5e5e5;
}

.subscription-item h3 {
  margin: 5px 0 10px 0;
  font-size: 18px;
  color: #ccc;
}

.subscription-item p {
  font-size: 16px;
  color: #fff;
}

.error-message {
  color: #ff1111;
  font-weight: bold;
}

.flex-row {
  display: flex;
}
.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.login .footer {
  height: 57px;
}

@media (max-width: 575px) and (min-width: 279px) {
  * {
    font-size: 11px !important;
  }

  nav {
    width: auto;
  }

  .welcome-section h1 {
    font-size: 16px !important;
    font-weight: bold;
  }

  .space-x-4 {
    margin-right: unset;
    text-align: end;
  }
  .footer {
    gap: 0;
    padding: 3px;
  }
  .welcome-section {
    height: 74vh;
  }

  hr {
    width: 100%; /* Fallback for older browsers */
    width: -webkit-fill-available; /* WebKit-based browsers */
    width: -moz-available; /* Firefox */
    width: fill-available; /* Future standard */
  }
  .items-center img {
    margin-left: 0;
  }

  .footer .green-color .image-size {
    width: 15px;
  }
  .welcome-logo {
    height: 40px;
    width: 40px;
    /* margin: 0 32px; */
  }
  .suggested-topics .card {
    width: 90px;
  }

  .signup-login .welcome-section2 h1 {
    font-size: 16px !important;
    font-weight: bold;
  }
  .css-1iak4fk {
    width: 29vh !important;
  }
}

@media (max-height: 700px) and (min-height: 1px) {
  .welcome-section {
    height: 74vh;
  }
  .content-signup-login {
    height: auto !important;
  }
}
