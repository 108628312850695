/* @import "@radix-ui/colors/black-alpha.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/violet.css"; */

/* reset */
/* button,
fieldset,
input {
  all: unset;
} */
.PopoverContent {
  position: absolute;
  right: -60px;
  top: -18px;

  border-radius: 4px;
  padding: 20px;
  width: 120px;
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 1201;
}

.PopoverContent:focus {
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px, 0 0 0 2px var(--violet-7);
}

.PopoverContent[data-state='open'][data-side='top'] {
  animation-name: slideDownAndFade;
}

.PopoverContent[data-state='open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}

.PopoverContent[data-state='open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}

.PopoverContent[data-state='open'][data-side='left'] {
  animation-name: slideRightAndFade;
}

.PopoverArrow {
  fill: white;
}

.PopoverClose {
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet-11);
  position: absolute;
  top: 5px;
  right: 5px;
}

.PopoverClose:hover {
  background-color: var(--violet-4);
}

.PopoverClose:focus {
  box-shadow: 0 0 0 2px var(--violet-7);
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet-11);
  /* background-color: white; */
  box-shadow: 0 2px 10px var(--black-a7);
}

.IconButton:hover {
  background-color: var(--violet-3);
}

.IconButton:focus {
  box-shadow: 0 0 0 2px black;
}

.Fieldset {
  display: flex;
  gap: 20px;
  align-items: center;
}

.Label {
  font-size: 13px;
  color: var(--violet-11);
  width: 75px;
}

.Input {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 13px;
  line-height: 1;
  color: var(--violet-11);
  box-shadow: 0 0 0 1px var(--violet-7);
  height: 25px;
}

.Input:focus {
  box-shadow: 0 0 0 2px var(--violet-8);
}

.Text {
  margin: 0;
  color: var(--mauve-12);
  font-size: 15px;
  line-height: 19px;
  font-weight: 500;
}

.delete-chat {
  border-radius: 50px;
  padding: 0.5rem 1rem;
  border: none;
  background-color: #cecece;
  color: black;
  cursor: pointer;
}

.delete-chat:hover {
  background: #ababab;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Three-dot menu styles */
.chat-history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.menu-trigger-button {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: inherit;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.menu-trigger-button:hover {
  opacity: 1;
}

.three-dot-icon {
  width: 20px;
  height: 20px;
}

.dropdown-content {
  max-width: 110px;
  background: rgb(4, 4, 4);
  border-radius: 6px;
  padding: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9rem;
}

.dropdown-item:hover {
  background: #50C878;
  color: white;
}


.dropdown-item.danger:hover {
  background: white;
  color: red;
}

/* Edit input field */
.chat-history-item input {
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 8px;
  font: inherit;
  width: 100%;
  color: black;
}


.icon {
  margin-right: 10px;
}