/* CodeBlocks.css */
.code-block {
  background: #2d2d2d;
  border-radius: 8px;
  margin: 1rem 0;
  overflow: hidden;
}

.code-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background: #1a1a1a;
  color: #fff;
}

.copy-btn {
  background: none;
  border: none;
  padding: 6px;
  cursor: pointer;
  color: #fff;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

/* .copy-btn:hover {
  background-color: #247027;
} */

.copy-btn.copied {
  color: #6cda70;
}

.copy-icon, .check-icon {
  width: 20px;
  height: 20px;
}

.check-icon {
  color: #fff;
}

.code-pre {
  margin: 0;
  padding: 1rem;
  overflow-x: auto;
}